.alert-outlet {
    z-index: 2000;
}

.alert-danger {
    color: white;
    background-color: rgba(250, 92, 124, 1);
}

.alert-success{
    color:white;
    background-color:rgba(10, 207, 151, 1) ;
}